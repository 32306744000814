import styled from "@emotion/styled";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import DesktopHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
const HEADER_LOGO_HORIZONTAL_PADDING = `${DesktopHeaderConstants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
export const StyledHoverableCardsGridComponent = styled.div`
  max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  min-width: ${TABLET_QUERY_MAX_WIDTH};
  margin: auto;
  display: flex;
  gap: 8px;
  padding: 0 ${HEADER_LOGO_HORIZONTAL_PADDING};
  justify-content: center;
`;
