import styled from "@emotion/styled";
import Colors from "styles/colors";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { getTheme } from "segments/segments-service";
const CARD_MAX_PADDING = 32;
const DEFAULT_TITLE_FONT_SIZE = "24px";
const getResponsiveSizeCalc = (size, resizeFactor, minimumSize)=>`calc(max(100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH} * ${size} * ${resizeFactor}, ${minimumSize}))`;
export const StyledHoverableCards = styled.div`
  position: relative;
  flex: 1;
  color: ${(props)=>{
    var _getTheme;
    return (_getTheme = getTheme(props.hoveredColor)) === null || _getTheme === void 0 ? void 0 : _getTheme.color;
}};
  background-color: ${(props)=>Colors[props.defaultColor]};
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.5s ease-in-out, clip-path 0.5s ease-in-out;
  clip-path: inset(10px 0 10px 0 round 8px);

  @media (max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    .card-title {
      font-size: ${({ titleSize, textResizeConfig })=>getResponsiveSizeCalc(titleSize, textResizeConfig === null || textResizeConfig === void 0 ? void 0 : textResizeConfig.resizeFactor, (textResizeConfig === null || textResizeConfig === void 0 ? void 0 : textResizeConfig.minimumFont) || DEFAULT_TITLE_FONT_SIZE)};
      line-height: ${({ textResizeConfig, titleLineHeight })=>getResponsiveSizeCalc(titleLineHeight, textResizeConfig === null || textResizeConfig === void 0 ? void 0 : textResizeConfig.resizeFactor, "32px")};
    }

    .paragraph-wrapper .paragraph-body {
      font-size: calc(max(100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH} * 16, 16px));
      line-height: calc(max(100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH} * 32, 24px));
    }
  }

  .card-title {
    transform: scale(0.8);
    transform-origin: center left;
    transition: transform 0.3s ease-in-out;
    font-weight: 600;
  }

  .paragraph-body-wrapper {
    overflow: visible;
  }

  .asset-container {
    position: relative;
    width: 100%;
    z-index: 0;
    display: flex;

    .default-asset,
    .selected-asset {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.5s ease-in-out;
      
      img, svg, video, * {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
      }
    }

    .selected-asset {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .paragraph-container {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    padding: ${CARD_MAX_PADDING}px;
    
    @media (max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
      padding: ${`calc(max(100vw / ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH} * ${CARD_MAX_PADDING}, 20px))`};
    }
  }

  &.selected {
    background-color: ${(props)=>Colors[props.hoveredColor]};
    clip-path: inset(0 0 0 0 round 8px);

    .card-title {
      transform: scale(1);
    }

    .paragraph-body-wrapper {
      opacity: 1;
    }

    .selected-asset {
      opacity: 1;
    }

    .default-asset {
      opacity: 0;
    }

    transition: all 0.5s ease-in-out;
  }

  &:not(.selected) {
    background-color: ${(props)=>Colors[props.defaultColor]};
    clip-path: inset(10px 0 10px 0 round 8px);

    .asset-container {
      opacity: 0.7;
    }

    .selected-asset {
      opacity: 0;
    }

    .default-asset {
      opacity: 1;
    }
  }
`;
